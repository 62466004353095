import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined
  );
};

const getAds = (params) => {
  return axiosClient.get("/popup", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getAdsDetail = (id, options) => {
  return axiosClient.get("popup/" + id, {
    ...options,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const postAds = (data) => {
  return axiosClient.post("popup", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putAds = (data) => {
  return axiosClient.put("popup/" + data.id, data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putAdsStatus = (data) => {
  return axiosClient.put("popup-status/" + data.id, data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export { getAds, getAdsDetail, postAds, putAds, putAdsStatus };
