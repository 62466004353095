import axiosClient from "../axiosClient"

const getToken = () => {
    return (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined;
}

const getRequests = (params, option) => {
    return axiosClient.get("requests", {
        params,
        ...option,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getRequest = (id) => {
    return axiosClient.get("requests/" + id, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putRequest = ({id, values}) => {
    return axiosClient.put("requests-status/" + id, {...values}, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

export {
    getRequests,
    getRequest,
    putRequest
}