import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined
  );
};

const postAnswer = (data) => {
  return axiosClient.post("answers", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getAnswerDetail = (id) => {
  return axiosClient.get("answers/" + id, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putAnswer = (data) => {
  return axiosClient.put("answers/" + data.id, data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putAnswerOrder = (data) => {
  return axiosClient.put("answers-order", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export { postAnswer, putAnswer, getAnswerDetail, putAnswerOrder };
