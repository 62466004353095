import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { getAds, getAdsDetail, putAds, putAdsStatus } from "./adsAPI";
import axios from "axios";

const initialState = {
  list: undefined,
  detail: undefined,
  status: "idle",
};

export const getAdsAsync = createAsyncThunk("ads", async (data, thunkAPI) => {
  try {
    const response = await getAds(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getAdsDetailAsync = createAsyncThunk(
  "adsDetail",
  async (data, thunkAPI) => {
    const source = axios.CancelToken.source();
    thunkAPI.signal.addEventListener("abort", () => {
      source.cancel();
    });
    try {
      const response = await getAdsDetail(data, {
        cancelToken: source.token,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const putAdsAsync = createAsyncThunk(
  "putAds",
  async (data, thunkAPI) => {
    try {
      const response = await putAds(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const putAdsStatusAsync = createAsyncThunk(
  "putAdsStatus",
  async (data, thunkAPI) => {
    try {
      const response = await putAdsStatus(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const adsSlice = createSlice({
  name: "lang",
  initialState,
  reducers: {
    // changeLang: (state, action) => {
    //     console.log("selected action", action)
    //     state.lang = action.payload
    // }
    // updateStatus: (state, action) => {
    //   let [key] = Object.keys(action.payload);
    //   state.status[key] = action.payload[key];
    // },
    setStatus: (state, action) => {
      state.status = action?.payload || "idle";
    },
    clearAdsDetail: (state, action) => {
      state.detail = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getAdsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAdsAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = "idle";
      })
      .addCase(getAdsAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getAdsError",
          };
        }
      })

      .addCase(getAdsDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAdsDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = "idle";
      })
      .addCase(getAdsDetailAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getAdsDetailError",
          };
        }
      })

      .addCase(putAdsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(putAdsAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        message.success("Cập nhật thành công");
        setStatus("idle");
        state.status = "idle";
      })
      .addCase(putAdsAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Cập nhật thất bại!");
          state.error = {
            payload: action.payload,
            title: "putDetailError",
          };
        }
      })

      .addCase(putAdsStatusAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(putAdsStatusAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        message.success("Cập nhật trạng thái thành công");
        setStatus("idle");
        state.status = "done";
      })
      .addCase(putAdsStatusAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Cập nhật trạng thái thất bại!");
          state.error = {
            payload: action.payload,
            title: "putAdsStatusError",
          };
        }
      });
  },
});

export const adsStatus = (state) => state.ads.status;
export const selectAds = (state) => state.ads.list;
export const selectAdsDetail = (state) => state.ads.detail;

export const { setStatus, clearAdsDetail } = adsSlice.actions;

export default adsSlice.reducer;
