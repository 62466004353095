import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined
  );
};

const getSurveys = (params) => {
  return axiosClient.get("questions", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getSurveyDetail = (id) => {
  return axiosClient.get("questions/" + id, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const postSurvey = (data) => {
  return axiosClient.post("questions", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putSurvey = (data) => {
  return axiosClient.put("questions/" + data.id, data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putSurveyOrder = (values) => {
  return axiosClient.put("questions-order", values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export { getSurveys, getSurveyDetail, putSurvey, postSurvey, putSurveyOrder };
