import axiosClient from "../axiosClient"

const getToken = () => {
    return (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined;
}

const getUsers = (params) => {
    return axiosClient.get("users", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getUserDetail = (id) => {
    return axiosClient.get("users/" + id, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const postUser = (data) => {
    return axiosClient.post("users", data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putUser = (data) => {
    return axiosClient.put("users/" + data.id, data.values, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putUserStatus = (id, data) => {
    return axiosClient.put("users-status/" + id, data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putUserPassword = (data) => {
    return axiosClient.put("password", data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putPasswordForUser = (id, data) => {
    return axiosClient.put("users-password/" + id, data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

export {
    getUsers,
    getUserDetail,
    postUser,
    putUser,
    putUserStatus,
    putUserPassword,
    putPasswordForUser,
}