import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined
  );
};

const getTrackings = (params, option) => {
  return axiosClient.get("trackings", {
    params,
    ...option,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getTracking = (id) => {
  return axiosClient.get("trackings/" + id, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putTracking = ({ id, values }) => {
  return axiosClient.put(
    "trackings-status/" + id,
    { ...values },
    {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    }
  );
};

export { getTrackings, getTracking, putTracking };
