import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  getSurveyDetail,
  getSurveys,
  putSurvey,
  putSurveyOrder,
} from "./surveyAPI";

const initialState = {
  list: undefined,
  detail: undefined,
  status: "idle",
};

export const getSurveyAsync = createAsyncThunk(
  "survey",
  async (data, thunkAPI) => {
    try {
      const response = await getSurveys(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getSurveyDetailAsync = createAsyncThunk(
  "surveyDetail",
  async (data, thunkAPI) => {
    try {
      const response = await getSurveyDetail(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const putSurveyAsync = createAsyncThunk(
  "putSurvey",
  async (data, thunkAPI) => {
    try {
      const response = await putSurvey(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const putSurveyOrderAsync = createAsyncThunk(
  "putSurveyOrder",
  async (data, thunkAPI) => {
    try {
      const response = await putSurveyOrder(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const surveySlice = createSlice({
  name: "lang",
  initialState,
  reducers: {
    // changeLang: (state, action) => {
    //     console.log("selected action", action)
    //     state.lang = action.payload
    // }
    setStatus: (state, action) => {
      state.status = action?.payload || "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getSurveyAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSurveyAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = "idle";
      })
      .addCase(getSurveyAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu khảo sát thất bại!");
          state.error = {
            payload: action.payload,
            title: "getSurveyError",
          };
        }
      })
      .addCase(getSurveyDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSurveyDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = "idle";
      })
      .addCase(getSurveyDetailAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu khảo sát thất bại!");
          state.error = {
            payload: action.payload,
            title: "getSurveyDetailError",
          };
        }
      })
      .addCase(putSurveyAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(putSurveyAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        message.success("Cập nhật câu hỏi thành công");
        state.status = "idle";
      })
      .addCase(putSurveyAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Cập nhật câu hỏi thất bại!");
          state.error = {
            payload: action.payload,
            title: "putSurveyDetailError",
          };
        }
      })
      .addCase(putSurveyOrderAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(putSurveyOrderAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        message.success("Cập nhật câu hỏi thành công");
        state.status = "done";
      })
      .addCase(putSurveyOrderAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Cập nhật câu hỏi thất bại!");
          state.error = {
            payload: action.payload,
            title: "putSurveyDetailError",
          };
        }
      });
  },
});

export const surveyStatus = (state) => state.survey.status;
export const selectSurvey = (state) => state.survey.list;
export const selectSurveyDetail = (state) => state.survey.detail;

export const { setStatus } = surveySlice.actions;

export default surveySlice.reducer;
