import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined
  );
};

const getNews = (params) => {
  return axiosClient.get("news", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const getNewsDetail = (id, options) => {
  return axiosClient.get("news/" + id, {
    ...options,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const postNews = (data) => {
  return axiosClient.post("news", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putNews = (data) => {
  return axiosClient.put("news/" + data.id, data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putNewsStatus = (data) => {
  return axiosClient.put("news-status/" + data.id, data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putHotNews = (data) => {
  return axiosClient.put("news-hot", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putPinNews = (data) => {
  return axiosClient.put("news-pin/" + data.id, data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export {
  getNews,
  postNews,
  getNewsDetail,
  putNews,
  putNewsStatus,
  putHotNews,
  putPinNews,
};
