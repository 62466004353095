import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { login } from './authAPI';

const initialState = {
  token: (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined,
  info: (localStorage?.auth && JSON.parse(localStorage.auth)) || undefined,
  status: 'idle',
};

export const authAsync = createAsyncThunk(
  'login/post',
  async (data, {rejectWithValue}) => {
    try{

      const response = await login(data);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch(error){
      return rejectWithValue(error.response)
    }
  }
);

export const authSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
      logout: (state) => {
        state.token = undefined;
        state.info = undefined;
        localStorage.removeItem("auth")
        localStorage.removeItem("token")
        return state
      }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(authAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(authAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.token = action.payload.token;
        state.info = action.payload;
      })
      .addCase(authAsync.rejected, (state, action) => {
        state.status = 'idle';
        if(action.payload.status === 403){
          message.error("Tài khoản hoặc mật khẩu không đúng!")
        }
        else{
          message.error("Lỗi liên kết hệ thống!")
        }
      })
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
