import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
    getRequests,
    getRequest,
    putRequest
} from './requestAPI';

const initialState = {
    list: undefined,
    detail: undefined,
    status: {
        list: 'idle',
        detail: 'idle',
        update: 'idle'
    },
};

export const getRequestsAsync = createAsyncThunk(
    'requests',
    async (data, thunkAPI) => {
        try{
            const response = await getRequests(data);
            return response.data;
        } catch(error){
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
export const getRequestDetailAsync = createAsyncThunk(
    'requestDetail',
    async (data, thunkAPI) => {
        try{
            const response = await getRequest(data);
            return response.data;
        } catch(error){
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
export const putRequestStatusAsync = createAsyncThunk(
    'requestStatus',
    async (data, thunkAPI) => {
        try{
            const response = await putRequest(data);
            return response.data;
        } catch(error){
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
  
export const requestSlice = createSlice({
    name: 'lang',
    initialState,
    reducers: {
        updateStatus: (state, action) => {
            let [key] = Object.keys(action.payload)
            state.status[key] = action.payload[key]
        },
        clearRequests: state => {
            state.list = undefined
            state.status.list = "idle"
        },
        clearRequestDetail: state => {
            state.detail = undefined
            state.status.detail = "idle"
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
      builder
        .addCase(getRequestsAsync.pending, (state) => {
            state.status.list = 'loading';
        })
        .addCase(getRequestsAsync.fulfilled, (state, action) => {
            state.list = action.payload
            state.status.list = 'idle';
        })
        .addCase(getRequestsAsync.rejected, (state, action) => {
            state.status.list = 'idle';
            if(action?.error?.name !== "AbortError"){
                message.error("Lấy dữ liệu thất bại!")
                state.error = {
                    payload: action.payload,
                    title: "getRequests"
                }
            }
        })
        .addCase(getRequestDetailAsync.pending, (state) => {
            state.status.detail = 'loading';
        })
        .addCase(getRequestDetailAsync.fulfilled, (state, action) => {
            state.detail = action.payload
            state.status.detail = 'idle';
        })
        .addCase(getRequestDetailAsync.rejected, (state, action) => {
            state.status.detail = 'idle';
            if(action?.error?.name !== "AbortError"){
                message.error("Lấy dữ liệu thất bại!")
                state.error = {
                    payload: action.payload,
                    title: "getRequestDetail"
                }
            }
        })
        .addCase(putRequestStatusAsync.pending, (state) => {
            state.status.update = 'loading';
        })
        .addCase(putRequestStatusAsync.fulfilled, (state, action) => {
            state.detail = action.payload
            state.status.update = 'done';
        })
        .addCase(putRequestStatusAsync.rejected, (state, action) => {
            state.status.update = 'idle';
            if(action?.error?.name !== "AbortError"){
                message.error("Lấy dữ liệu thất bại!")
                state.error = {
                    payload: action.payload,
                    title: "putRequestStatus"
                }
            }
        })
    },
});

export const requestStatus = state => state.request.status
export const selectRequests = state => state.request.list
export const selectRequestDetail = state => state.request.detail

export const { updateStatus, clearRequestDetail, clearRequests } = requestSlice.actions;
  
export default requestSlice.reducer;
  
