import axiosClient from "../axiosClient"

const getToken = () => {
    return (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined;
}

const getLangs = (params) => {
    return axiosClient.get("languages", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

export {
    getLangs,
}