import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined
  );
};

const getSurveyCategories = (params) => {
  return axiosClient.get("question-categories", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const getSurveyCategoryDetail = (id) => {
  return axiosClient.get("question-categories/" + id, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const postSurveyCategory = (data) => {
  return axiosClient.post("question-categories", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putSurveyCategory = (data) => {
  return axiosClient.put("question-categories/" + data.id, data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export {
  getSurveyCategories,
  getSurveyCategoryDetail,
  postSurveyCategory,
  putSurveyCategory,
};
