import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { getTrackings, getTracking, putTracking } from "./trackingAPI";

const initialState = {
  list: undefined,
  detail: undefined,
  status: {
    list: "idle",
    detail: "idle",
    update: "idle",
  },
};

export const getTrackingsAsync = createAsyncThunk(
  "trackings",
  async (data, thunkAPI) => {
    try {
      const response = await getTrackings(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getTrackingDetailAsync = createAsyncThunk(
  "trackingDetail",
  async (data, thunkAPI) => {
    try {
      const response = await getTracking(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const putTrackingStatusAsync = createAsyncThunk(
  "trackingStatus",
  async (data, thunkAPI) => {
    try {
      const response = await putTracking(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const trackingSlice = createSlice({
  name: "lang",
  initialState,
  reducers: {
    updateStatus: (state, action) => {
      let [key] = Object.keys(action.payload);
      state.status[key] = action.payload[key];
    },
    clearTrackings: (state) => {
      state.list = undefined;
      state.status.list = "idle";
    },
    clearTrackingDetail: (state) => {
      state.detail = undefined;
      state.status.detail = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getTrackingsAsync.pending, (state) => {
        state.status.list = "loading";
      })
      .addCase(getTrackingsAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status.list = "idle";
      })
      .addCase(getTrackingsAsync.rejected, (state, action) => {
        state.status.list = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getTrackings",
          };
        }
      })
      .addCase(getTrackingDetailAsync.pending, (state) => {
        state.status.detail = "loading";
      })
      .addCase(getTrackingDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status.detail = "idle";
      })
      .addCase(getTrackingDetailAsync.rejected, (state, action) => {
        state.status.detail = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getTrackingDetail",
          };
        }
      })
      .addCase(putTrackingStatusAsync.pending, (state) => {
        state.status.update = "loading";
      })
      .addCase(putTrackingStatusAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status.update = "done";
      })
      .addCase(putTrackingStatusAsync.rejected, (state, action) => {
        state.status.update = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "putTrackingStatus",
          };
        }
      });
  },
});

export const trackingStatus = (state) => state.tracking.status;
export const selectTrackings = (state) => state.tracking.list;
export const selectTrackingDetail = (state) => state.tracking.detail;

export const { updateStatus, clearTrackingDetail, clearTrackings } =
  trackingSlice.actions;

export default trackingSlice.reducer;
