import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  getAnswerDetail,
  postAnswer,
  putAnswer,
  putAnswerOrder,
} from "./answerAPI";

const initialState = {
  list: undefined,
  detail: undefined,
  status: "idle",
};
export const getAnswerDetailAsync = createAsyncThunk(
  "answerDetail",
  async (data, thunkAPI) => {
    try {
      const response = await getAnswerDetail(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const putAnswerAsync = createAsyncThunk(
  "putAnswer",
  async (data, thunkAPI) => {
    try {
      const response = await putAnswer(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const postAnswerAsync = createAsyncThunk(
  "postAnswer",
  async (data, thunkAPI) => {
    try {
      const response = await postAnswer(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const putAnswerOrderAsync = createAsyncThunk(
  "putAnswerOrder",
  async (data, thunkAPI) => {
    try {
      const response = await putAnswerOrder(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const answerSlice = createSlice({
  name: "lang",
  initialState,
  reducers: {
    // changeLang: (state, action) => {
    //     console.log("selected action", action)
    //     state.lang = action.payload
    // }
    setStatus: (state, action) => {
      state.status = action?.payload || "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(putAnswerAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(putAnswerAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        message.success("Cập nhật đáp án thành công");
        state.status = "idle";
      })
      .addCase(putAnswerAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Cập nhật đáp án thất bại!");
          state.error = {
            payload: action.payload,
            title: "putAnswerError",
          };
        }
      })
      .addCase(putAnswerOrderAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(putAnswerOrderAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        message.success("Cập nhật đáp án thành công");
        state.status = "done";
      })
      .addCase(putAnswerOrderAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Cập nhật đáp án thất bại!");
          state.error = {
            payload: action.payload,
            title: "putAnswerOrderError",
          };
        }
      })
      .addCase(getAnswerDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAnswerDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = "idle";
      })
      .addCase(getAnswerDetailAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu đáp án thất bại!");
          state.error = {
            payload: action.payload,
            title: "getAnswerDetailError",
          };
        }
      })
      .addCase(postAnswerAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postAnswerAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        message.success("Tạo đáp án thành công");
        state.status = "done";
      })
      .addCase(postAnswerAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Tạo đáp án thất bại!");
          state.error = {
            payload: action.payload,
            title: "postAnswerError",
          };
        }
      });
  },
});

export const answerStatus = (state) => state.answer.status;
export const selectAnswer = (state) => state.answer.list;
export const selectAnswerDetail = (state) => state.answer.detail;

export const { setStatus } = answerSlice.actions;

export default answerSlice.reducer;
