import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import {
  getNews,
  getNewsDetail,
  putHotNews,
  putNews,
  putNewsStatus,
  putPinNews,
} from "./newsAPI";

const initialState = {
  list: undefined,
  detail: undefined,
  status: "idle",
};

export const getNewsAsync = createAsyncThunk("news", async (data, thunkAPI) => {
  try {
    const response = await getNews(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
export const getNewsDetailAsync = createAsyncThunk(
  "newsDetail",
  async (data, thunkAPI) => {
    const source = axios.CancelToken.source();
    thunkAPI.signal.addEventListener("abort", () => {
      source.cancel();
    });
    try {
      const response = await getNewsDetail(data, {
        cancelToken: source.token,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const putNewsAsync = createAsyncThunk(
  "putNews",
  async (data, thunkAPI) => {
    try {
      const response = await putNews(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const putNewsStatusAsync = createAsyncThunk(
  "putNewsStatus",
  async (data, thunkAPI) => {
    try {
      const response = await putNewsStatus(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const putHotNewsAsync = createAsyncThunk(
  "putHotNews",
  async (data, thunkAPI) => {
    try {
      const response = await putHotNews(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const putPinNewsAsync = createAsyncThunk(
  "putPinNews",
  async (data, thunkAPI) => {
    try {
      const response = await putPinNews(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const newsSlice = createSlice({
  name: "lang",
  initialState,
  reducers: {
    // changeLang: (state, action) => {
    //     console.log("selected action", action)
    //     state.lang = action.payload
    // }
    setStatus: (state, action) => {
      state.status = action?.payload || "idle";
    },
    clearNewsDetail: (state, action) => {
      state.detail = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getNewsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getNewsAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = "idle";
      })
      .addCase(getNewsAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu bài viết thất bại!");
          state.error = {
            payload: action.payload,
            title: "getNewsError",
          };
        }
      })

      .addCase(getNewsDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getNewsDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = "idle";
      })
      .addCase(getNewsDetailAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu bài viết thất bại!");
          state.error = {
            payload: action.payload,
            title: "getNewsDetailError",
          };
        }
      })
      .addCase(putNewsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(putNewsAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        message.success("Cập nhật bài viết thành công");
        setStatus("idle");
        state.status = "idle";
      })
      .addCase(putNewsAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Cập nhật bài viết thất bại!");
          state.error = {
            payload: action.payload,
            title: "putNewsDetailError",
          };
        }
      })
      .addCase(putNewsStatusAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(putNewsStatusAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        message.success("Cập nhật trạng thái thành công");
        setStatus("idle");
        state.status = "done";
      })
      .addCase(putNewsStatusAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Cập nhật trạng thái thất bại!");
          state.error = {
            payload: action.payload,
            title: "putNewsStatusError",
          };
        }
      })

      .addCase(putHotNewsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(putHotNewsAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        message.success("Cập nhật bài viết thành công!");
        setStatus("idle");
        state.status = "done";
      })
      .addCase(putHotNewsAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Cập nhật bài viết thất bại!");
          state.error = {
            payload: action.payload,
            title: "putHotNewsError",
          };
        }
      })

      .addCase(putPinNewsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(putPinNewsAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        message.success("Cập nhật bài viết thành công!");
        setStatus("idle");
        state.status = "done";
      })
      .addCase(putPinNewsAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Cập nhật bài viết thất bại!");
          state.error = {
            payload: action.payload,
            title: "putPinNewsError",
          };
        }
      });
  },
});

export const newsStatus = (state) => state.news.status;
export const selectNews = (state) => state.news.list;
export const selectNewsDetail = (state) => state.news.detail;

export const { setStatus, clearNewsDetail } = newsSlice.actions;

export default newsSlice.reducer;
