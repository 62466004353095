import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import authReducer from "../features/Auth/authSlice";
import userReducer from "../features/user/userSlice";
import langReducer from "../features/lang/langSlice";
import newsReducer from "../features/news/newsSlice";
import newsCategoryReducer from "../features/newsCategory/newsCategorySlice";
import requestReducer from "../features/request/requestSlice";
import surveyReducer from "../features/survey/surveySlice";
import surveyCategoryReducer from "../features/surveyCategory/surveyCategorySlice";
import answerReducer from "../features/answer/answerSlice";
import trackingReducer from "../features/tracking/trackingSlice";
import adsReducer from "../features/ads/adsSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    users: userReducer,
    lang: langReducer,
    news: newsReducer,
    newsCategory: newsCategoryReducer,
    request: requestReducer,
    survey: surveyReducer,
    surveyCategory: surveyCategoryReducer,
    answer: answerReducer,
    tracking: trackingReducer,
    ads: adsReducer,
  },
});
