import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getNewsCategories, getNewsCategoryDetail } from './newsCategoryAPI';

const initialState = {
    list: undefined,
    detail: undefined,
    status: 'idle',
};

export const getNewsCategoryAsync = createAsyncThunk(
    'newsCategory',
    async (data) => {
        try{
            const response = await getNewsCategories(data);
            return response.data;
        } catch(error){
            return error
        }
    }
);
export const getNewsCategoryDetailAsync = createAsyncThunk(
    'newsCategoryDetail',
    async (data) => {
        try{
            const response = await getNewsCategoryDetail(data);
            return response.data;
        } catch(error){
            return error
        }
    }
);
  
export const newsCategorySlice = createSlice({
    name: 'lang',
    initialState,
    reducers: {
        // changeLang: (state, action) => {
        //     console.log("selected action", action)
        //     state.lang = action.payload
        // }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
      builder
        .addCase(getNewsCategoryAsync.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(getNewsCategoryAsync.fulfilled, (state, action) => {
            state.list = action.payload
            state.status = 'idle';
        })
        .addCase(getNewsCategoryDetailAsync.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(getNewsCategoryDetailAsync.fulfilled, (state, action) => {
            state.detail = action.payload
            state.status = 'idle';
        });
    },
});

export const newsCategoryStatus = state => state.newsCategory.status
export const selectNewsCategories = state => state.newsCategory.list
export const selectNewsCategoryDetail = state => state.newsCategory.detail

export const { } = newsCategorySlice.actions;
  
export default newsCategorySlice.reducer;
  
