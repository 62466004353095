import axiosClient from "../axiosClient"

const getToken = () => {
    return (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined;
}

const getNewsCategories = (params) => {
    return axiosClient.get("news-categories", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getNewsCategoryDetail = (id) => {
    return axiosClient.get("news-categories/"+id, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const postNewsCategory = (data) => {
    return axiosClient.post("news-categories", data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putNewsCategory = (data) => {
    return axiosClient.put("news-categories/" + data.id, data.values, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

export {
    getNewsCategories,
    postNewsCategory,
    getNewsCategoryDetail,
    putNewsCategory
}