import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  getSurveyCategories,
  getSurveyCategoryDetail,
  postSurveyCategory,
  putSurveyCategory,
} from "./surveyCategoryAPI";

const initialState = {
  list: undefined,
  detail: undefined,
  status: "idle",
};

export const getSurveyCategoryAsync = createAsyncThunk(
  "surveyCategory",
  async (data) => {
    try {
      const response = await getSurveyCategories(data);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);
export const getSurveyCategoryDetailAsync = createAsyncThunk(
  "surveyCategoryDetail",
  async (data) => {
    try {
      const response = await getSurveyCategoryDetail(data);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const postSurveyCategoryAsync = createAsyncThunk(
  "postSurveyCategory",
  async (data, thunkAPI) => {
    try {
      const response = await postSurveyCategory(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const putSurveyCategoryAsync = createAsyncThunk(
  "putSurveyCategory",
  async (data, thunkAPI) => {
    try {
      const response = await putSurveyCategory(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const surveyCategorySlice = createSlice({
  name: "lang",
  initialState,
  reducers: {
    // changeLang: (state, action) => {
    //     console.log("selected action", action)
    //     state.lang = action.payload
    // }
    setStatus: (state, action) => {
      state.status = action?.payload || "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getSurveyCategoryAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSurveyCategoryAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = "idle";
      })
      .addCase(getSurveyCategoryDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSurveyCategoryDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = "idle";
      })
      .addCase(putSurveyCategoryAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(putSurveyCategoryAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        message.success("Cập nhật danh mục thành công");
        setStatus("idle");
        state.status = "done";
      })
      .addCase(putSurveyCategoryAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Cập nhật danh mục thất bại!");
          state.error = {
            payload: action.payload,
            title: "putSurveyCategoryError",
          };
        }
      })
      .addCase(postSurveyCategoryAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postSurveyCategoryAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        message.success("Tạo danh mục thành công");
        setStatus("idle");
        state.status = "done";
      })
      .addCase(postSurveyCategoryAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Tạo danh mục thất bại!");
          state.error = {
            payload: action.payload,
            title: "postSurveyCategoryError",
          };
        }
      });
  },
});

export const surveyCategoryStatus = (state) => state.surveyCategory.status;
export const selectSurveyCategories = (state) => state.surveyCategory.list;
export const selectSurveyCategoryDetail = (state) =>
  state.surveyCategory.detail;

export const { setStatus } = surveyCategorySlice.actions;

export default surveyCategorySlice.reducer;
